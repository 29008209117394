.custom-table {
  width: 100%; /* Set the width to your desired value */
}

.align-right {
  text-align: right;
}

.force-line-break {
  white-space: pre-line; /* or white-space: pre-wrap; */
}

.table-bordered {
  border-width: 1px; /* Adjust the width as needed */
  border-style: solid; /* Set border style to solid */
  border-color: black; /* Set border color to black */
}

.table-bordered {
  border-width: 1px !important; /* Adjust the width as needed */
  border-style: solid !important; /* Set border style to solid */
  border-color: black !important; /* Set border color to black */
}

.table-bordered th,
.table-bordered td {
  border-width: 1px !important; /* Adjust the width as needed */
  border-style: solid !important; /* Set border style to solid */
  border-color: black !important; /* Set border color to black */
}
